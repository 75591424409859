/*
Copyright (C) COMPOFFICE-R Kft, RentIT - All Rights Reserved
Unauthorized copying of this file, via any medium is strictly prohibited
Proprietary and confidential

*/

@import "../../mixins";

#sponsors-details > article {

    text-align: center;

    .sponsor-img-cont {
        text-align: center;
        .sponsor-img {
            max-width: 100%;
            max-height: 150px;
        }
    }

    .btn-cnt {
        padding: 10pt 0;
        justify-content: center;
        div {
            flex: 0 0 auto;
        }
    }

    .sponsor-title {
        font-size: 16pt;
        text-align: center;
        padding-bottom: 10pt;
    }

    .sponsor-description {
        text-align: justify;
        padding-bottom: 10pt;
    }

    .sponsor-users {
        padding: 10pt 0;
        text-align: left;
        .item {
            order: 1;

            .info {
                padding: 0 dp(12);
            }

            .chevron {
                padding: 0 dp(4);
            }

            .favorite {

            }

            .header {
                .location {
                    margin-left: dp(16);
                }
            }
        }
    }
}
